<template>
    <!-- /test/app-table/providers -->
    <div class="page-test-app-table-providers container">
        <div class="row">
            <div :class="[ selected_row ? 'col-8' : 'col-12' ]">
                <app-table
                    class="providers-table"

                    title="Providers"

                    :cols="cols"
                    :rows="rows"

                    :loading="loading"

                    :clickable="true"
                    :selected-row="selected_row"

                    ref="providers"

                    @row-click="onRowClick"
                ></app-table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appTable from '@/components/app-table'

export default {
    components: {
        appTable,
    },

    data() {
        return {
            cols: [
                { key: 'spid',     title: 'spid',     highlight: true,               },
                { key: 'name',     title: 'name',                                    },
                { key: 'features', title: 'features',                  type: 'tags', },
            ],
            rows: [],

            loading: false,

            selected_row: null,
        }
    },

    computed: {
        ...mapGetters([
            'providers_features',
        ]),
    },

    created() {
        this.init()
    },

    mounted() {
        document.addEventListener('click', this.handleClickOutsideTable)
    },

    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutsideTable)
    },

    methods: {
        init() {
            this.getProviders()
        },

        onRowClick(row) {
            console.log('onRowClick', JSON.parse(JSON.stringify(row)))

            this.selected_row = row.uuid

            this.$nextTick(this.toggleProvidersResize)
        },

        handleClickOutsideTable(event) {
            if (!this.$refs.providers.$el.contains(event.target)) {
                this.selected_row = null

                this.$nextTick(this.toggleProvidersResize)
            }
        },

        toggleProvidersResize() {
            this.$refs.providers.watchingResize()
        },

        getProviders() {
            this.loading = true

            this.$store.dispatch('getProviders', {})
                .then(response => {

                    this.rows = response.Providers.map(provider => ({
                        uuid: provider.UUID,

                        SPID: provider.SPID,

                        spid: provider.SPID,

                        name: provider.Name,

                        features: this.getProviderFeaturesTags(provider.Features),
                    }))

                    this.loading = false
                })
                .catch(error => {
                    console.log(error)
                    this.rows = []
                    this.loading = false
                })
        },

        getProviderFeaturesTags(features) {
            let tags = []

            for (let i = 0, len = features.length; i < len; i++) {
                const feature = features[i]

                if (feature in this.providers_features) {
                    tags.push(this.providers_features[feature].Title)
                }
            }

            return tags
        },
    }
}
</script>

<style lang="scss">
.page-test-app-table-providers {
    .row {
        & > div {
            transition: $transition-duration-primary;
        }
    }

    .providers-table {
        margin: 48px 0;

        @include table-cols-width((150px, 115px, 305px), true);
    }
}

@media (max-width: $tablet-size) {
    .page-test-app-table-providers {
        .providers-table {
            @include table-cols-width((205px, 135px, 305px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-test-app-table-providers {
        .providers-table {
            @include table-cols-width-mobile((70px, 205px), true);
        }
    }
}
</style>